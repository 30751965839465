import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from './Firebase'
import 'firebase/firestore'

const ProfileThumb = ({ uid, userPublic, showName = true, linkToAccountAdmin = false, imageClassname = 'object-cover w-7 h-7 rounded-full'}) => {
    const auth = useContext(FirebaseContext)
    const { loadUserPublicDetailsFromUid } = auth

    const [profile, setProfile] = useState(userPublic)

    const loadProfileUrlFromUid = async uid => {
        setProfile(await loadUserPublicDetailsFromUid(uid))
    }

    useEffect(() => {
        if(uid) loadProfileUrlFromUid(uid)
    }, [uid]);

    if(!profile || !profile.photoURL) return <></>

    const { username, photoURL, displayName } = profile

    return (
        <a
            href={linkToAccountAdmin ? "/profile" : `/p/${username}`}
            className="inline-flex items-center space-x-1"
        >
            <img
                src={photoURL}
                alt={displayName}
                className={imageClassname}
                loading="lazy"
            />
            {showName && (
                <span className="post-card-author">
                    {displayName.split(" ")[0]}
                </span>
            )}
        </a>
    )
}
 
export default ProfileThumb;

