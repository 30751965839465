import React from "react"
import { PostCard, Pagination } from "."

const PostFeed = ({ posts, pageContext }) => {
    return (
        <>
            <section className="post-feed">
                {posts.map(({ node }) => (
                    <PostCard key={node.id} post={node} />
                ))}
            </section>
            <Pagination pageContext={pageContext} />
        </>
    )
}

export default PostFeed
