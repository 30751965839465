import React from 'react'
import PropTypes from 'prop-types'
import { X as XIcon } from "@styled-icons/heroicons-outline/X"
import { Menu as MenuIcon } from "@styled-icons/heroicons-outline/Menu"
import { ClientOnly } from "./"
import LoginOrProfile from "../idealline/LoginOrProfile"
import FirebaseProvider from "../idealline/Firebase"
import Logo from './../../images/open-racer.inline.svg'
import { Disclosure } from "@headlessui/react"

const navigation = [
    { name: "Tracks", href: "/tracks" },
    { name: "Karting", href: "/go-karting-tracks" },
    { name: "Getting Faster", href: "/tag/getting-faster" },
    { name: "Racing Lines", href: "/racing-line-editor" },
]
  
const Navigation = () => (
    <Disclosure
        as="nav"
        className="py-5 px-6vw lg:px-0 w-full sticky h-22 top-0 z-20 bg-white/80 backdrop-blur-md"
    >
        {({ open }) => (
            <div className='max-w-screen-lg mx-auto h-full'>
                <div className="h-10 flex items-center">
                    <a href="/">
                        <Logo className="w-36" />
                    </a>
                    <div className="hidden md:block">
                        <ul className="uppercase flex space-x-5 px-5 text-sm">
                            {navigation.map(({ name, href }) => (
                                <li key={name}>
                                    <a href={href}>{name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="ml-auto flex items-center space-x-3">
                        <ClientOnly>
                            <FirebaseProvider>
                                <LoginOrProfile />
                            </FirebaseProvider>
                        </ClientOnly>
                        <Disclosure.Button
                            as="button"
                            className="inline-flex items-center justify-center p-1 md:hidden rounded-full hover:bg-indigo-100 "
                        >
                            <>
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XIcon
                                        className="block w-6 h-6"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <MenuIcon
                                        className="block w-6 h-6"
                                        aria-hidden="true"
                                    />
                                )}
                            </>
                        </Disclosure.Button>
                    </div>
                </div>
                <Disclosure.Panel className="md:hidden pt-1 pb-2">
                    {navigation.map(({ name, href }) => (
                        <a
                            href={href}
                            key={name}
                            className="uppercase block text-indigo-800 bg-indigo-50 hover:bg-indigo-100 hover:text-indigo-800 my-3 px-4 py-2 rounded-full disabled:opacity-50 text-sm"
                        >
                            {name}
                        </a>
                    ))}
                </Disclosure.Panel>
            </div>
        )}
    </Disclosure>
)
export default Navigation
