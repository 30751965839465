import React, { useContext } from "react"
import ProfileThumb from "./ProfileThumb"
import { FirebaseContext } from "./Firebase"
import "firebase/auth"

export default (props) => {
    const auth = useContext(FirebaseContext)
    const { user, userPublic } = auth

    if (user === null) return <></>

    return (
        <>
            {!user || user.isAnonymous ? (
                <a
                    data-testid="login"
                    href="/profile"
                    className="inline-btn blue-bg"
                >
                    Sign In
                </a>
            ) : (
                <>
                    {userPublic && (
                        <ProfileThumb
                            userPublic={userPublic}
                            linkToAccountAdmin
                            showName={false}
                        />
                    )}
                </>
            )}
        </>
    )
}
