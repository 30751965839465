import React from "react"

function SvgGradient() {
    return (
        <svg
            style={{ width: 0, height: 0, position: "absolute" }}
            aria-hidden="true"
            focusable="false"
        >
            <defs>
                <filter id="glowshadow" x={0} y={0} width="105%" height="105%">
                    <feOffset
                        result="offOut"
                        in="SourceGraphic"
                        dx={1}
                        dy={1}
                    />
                    <feGaussianBlur
                        result="blurOut"
                        in="offOut"
                        stdDeviation={3}
                    />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
                <filter id="dropshadow">
                    <feOffset dx={4} dy={5} />
                    <feComponentTransfer>
                        <feFuncA type="linear" slope="0.25" />
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <linearGradient id="track-gradient" x1={0} y1={0} x2={1} y2={0}>
                <stop offset="0%" stopColor="#097FAA" />
                <stop offset="100%" stopColor="#5951A2" />
            </linearGradient>
        </svg>
    )
}

export default SvgGradient
