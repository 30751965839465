import React from "react"
import PropTypes from "prop-types"
import { Tags } from "@tryghost/helpers-gatsby"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostCard = ({ post }) => {
    const url = `/${post.slug}`
    const readingTime = readingTimeHelper(post)
    const image = getImage(post.feature_image_file)

    return (
        <article className="post-card">
            <a href={url} className="post-card-image-link">
                {image && (
                    <div className="post-card-image">
                        <GatsbyImage
                            className="object-cover w-full h-full"
                            image={image}
                            alt={post.title}
                        />
                    </div>
                )}
            </a>
            <div className="post-card-content">
                <a href={url} className="post-card-content-link mb-5">
                    <header className="post-card-header">
                        {post.tags && (
                            <div className="post-card-tags">
                                {" "}
                                <Tags
                                    post={post}
                                    visibility="public"
                                    autolink={false}
                                />
                            </div>
                        )}
                        {post.featured && <span>Featured</span>}
                        <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <section className="post-card-excerpt">
                        {post.excerpt}
                    </section>
                </a>
            </div>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
