import React from "react"

function Button({ onClick, children }) {
    return (
        <button
            onClick={onClick}
            className="my-3 px-3 py-1 border text-base uppercase border-gray-300 rounded-full text-gray-600 focus:outline-none focus:ring focus:border-indigo-200"
        >
            {children}
        </button>
    )
}

export default Button
