import React, { useState, useEffect, createContext } from 'react'
import app from "firebase/app"
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import { unstable_renderSubtreeIntoContainer } from 'react-dom'
import { nanoid } from 'nanoid'

const FirebaseContext = createContext(null)
export { FirebaseContext }

export default ({ children }) => {
    if (!app.apps.length) {
      app.initializeApp({
        apiKey: process.env.GATSBY_FB_API_KEY,
        authDomain: process.env.GATSBY_FB_AUTH_DOMAIN,
        databaseURL: process.env.GATSBY_FB_DB_URL,
        projectId: process.env.GATSBY_FB_PROJ_ID,
        storageBucket: process.env.GATSBY_FB_BUCKET,
        messagingSenderId: process.env.GATSBY_FB_MSG_ID,
        appId: process.env.GATSBY_FB_APP_ID,
        measurementId: process.env.GATSBY_FB_MEASUREMENT_ID
      })
      if (process.env.GATSBY_SITEURL.includes("localhost")) {
          app.auth().useEmulator("http://localhost:9099/", { disableWarnings: true })
          app.firestore().useEmulator("localhost", 8080)
          app.storage().useEmulator("localhost", 9199)
      }
    }


    const auth = useProvideAuth()

    return (
      <FirebaseContext.Provider value={ auth }>
        { children }
      </FirebaseContext.Provider>
    )
  }

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [userPublic, setUserPublic] = useState(null)
  const usersDb = app.firestore().collection(`users`)

  const signout = () => {
    return app
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
        setUserPublic(false)
      });
  };

  const signInAnonymouslyAndGetUser = async () => {
    // Returns the new user or if already signed in the current user
    const userResponse = await app.auth().signInAnonymously()
    return userResponse.user
  }


  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(user => {
      console.log("authentication changed", user)
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
      if (!user || user.isAnonymous) {
        if(user === false) setUserPublic(false)
        return
      }

      loadOrCreatePublicUser(user)
  }, [user]);

  useEffect(() => {
    if (!userPublic || !userPublic.photoURL) {
      if(userPublic === false) localStorage.removeItem('signedInProfilePhoto')
      return
    }

    localStorage.setItem('signedInProfilePhoto', userPublic.photoURL)
}, [userPublic]);


  const findUserData = (user, key) => {
    if(!user) return

    if(user[key]) return user[key]

    if(user.providerData) {
        const providerDataRes = user.providerData.find(f => f[key])
        return providerDataRes && providerDataRes[key]
    }
}

const loadUserPublicDetailsFromUid = async uid => {
    const usersRes = await usersDb
    .where("uid", "==", uid)
      .get()
    if(usersRes.empty) return


  const doc = usersRes.docs[0]
  const username = doc.id
  const userPublicData = doc.data()
  const userPublic = {username, ...userPublicData}
  
  console.log("user data loaded: ", userPublicData)

  return userPublic
}

  const loadUserPublicDetails = async user => {
    return await loadUserPublicDetailsFromUid(user.uid)
  }

  const loadUserPublicDetailsFromUsername = async username => {
    const userRes = await usersDb.doc(username).get()
    if(!userRes.exists) return "not found"

    const userPublicData = userRes.data()

    return {username, ...userPublicData}
  }

  const generateUserName = async fullName => {
    let username = fullName.replace(' ', '')
     username = username.replace(/\s+/g, '')
     username = username.replace(/\'+/g, '')
     username = username.replace(/-+/g, '')
     const usernameLowercase = username.toLowerCase()
     const usersRes = await usersDb.doc(usernameLowercase).get()
     if(!usersRes.exists) return usernameLowercase
     return `${username.toLowerCase()}-${nanoid(6)}`

  }

  const createUserPublicDetails = async user => {
    const { uid } = user
    const photoURL = findUserData(user, "photoURL")  || '/assets/no-profile-picture-icon.png'
    const displayName = findUserData(user, "displayName")

    if(!displayName) {
      console.error("no displayName defined")
      return
    }
    const username = await generateUserName(displayName)

    const userPublic = {
      uid,
      photoURL,
      displayName
    }

    try {
      const doc = usersDb.doc(username)
      await doc.set(userPublic, { merge: true })
      
      console.log("User public created with id: ", doc.id)
      return userPublic
    }
    catch(error) {
        console.error("Error creating document: ", error);
    }
  }
  

  const loadOrCreatePublicUser = async user => {
    let userPublic = await loadUserPublicDetails(user)
    if(!userPublic) {
      userPublic = await createUserPublicDetails(user)
    }

    setUserPublic(userPublic)
  }

  const requireAuth = action => {
    if (!user || user.isAnonymous) {
      window.location = "/profile?redirect=" + window.location.pathname
      return
    }
    
    action(user)
  }

  
  // Return the user object and auth methods
  return {
    app,
    user,
    userPublic,
    signout,
    signInAnonymouslyAndGetUser,
    loadUserPublicDetailsFromUsername,
    loadUserPublicDetailsFromUid,
    requireAuth
  };
}